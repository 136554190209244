import axios from 'axios';

class CategoriasAPI {
    uriBase = process.env.REACT_APP_URI_BASE;
    uriBaseMapa = process.env.REACT_APP_URI_BASE_MAPA;
    endPoint = 'api/Categorias';

    getCategorias(idCategorias) {
        return axios.get(this.uriBase + this.endPoint + '/');
    }

    postCategorias(idCategorias) {
        return axios.post(this.uriBase + this.endPoint, idCategorias);
    }

    postCategoriasMapa(idCategorias) {
        return axios.post(this.uriBaseMapa + 'Categorias', idCategorias);
    }    

}

export default CategoriasAPI;