import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import VpnKeySharpIcon from '@material-ui/icons/VpnKeySharp';

class LoginBase extends Component{
    
    render() {
        
        const {                        
            onLoguearClick,
            onCampoChange,            
            usuario,
            password,
            errorIngreso,
            errorTexto
        } = this.props;

        
        return(
            <div style={styleDiv}>                
                <img src="logoTexto.png" style={styleImg} alt="imagen login"/>

                <form style={formContainer}>
                                                            
                    <TextField type="text"
                                style={styleImg}
                               placeholder="Usuario"
                               margin="normal"
                               name="Usuario"
                               value={usuario}
                               InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AccountCircle />
                                  </InputAdornment>
                                ),
                              }}
                               onChange={e => onCampoChange('usuario', e.target.value)}/>
                    
                    <TextField type="password"
                               style={styleImg}
                               placeholder="Contraseña"
                               margin="normal"
                               name="Contraseña"
                               value={password}
                               error={errorIngreso}
                               helperText= {errorTexto}
                               InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <VpnKeySharpIcon />
                                  </InputAdornment>
                                ),
                              }}
                               onChange={e => onCampoChange('password', e.target.value)}/>                        

                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    <Button style = {{ backgroundColor:'#7a2682', color: '#ffffff' }}
                            variant="contained"                        
                            onClick={onLoguearClick}>
                        Ingresar
                    </Button>
                </form>
            </div>
        );
    }
}

const formContainer = {    
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto'
};

const styleImg = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
}

const styleDiv = {
    textAlign: 'center',
    display: 'grid'
}

export default LoginBase;
