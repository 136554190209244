import React, { Component } from 'react';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from "google-maps-react";
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import NearMeOutlined from '@material-ui/icons/NearMeOutlined';
import './MapaBase.css';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Helmet} from 'react-helmet';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export class MapaBase extends Component {

    constructor(props) {                
        super(props);      
    }
    
    render() {        
        const {
            zoom,
            center,
            google,
            prestadores,
            listaCategoriasCombobox,
            categorias,
            ubicacion,            
            showInfo,
            onClickMapa,
            onCategoriaChange,
            onDistanciaChange,
            onUbicacionChange,              
            teclaPresionadaUbicacion,  
            prestadorSeleccionado,           
            showInfoMap,
            mostrarPrestadorSeleccionado,
            mostrarCagando
        } = this.props;

        let valorCategorias;           
        if (categorias.length > 0)
        {              
            valorCategorias = listaCategoriasCombobox;            
        }      
        
        return (
          <React.Fragment>
                
            <div className="application">
              <Helmet>
                <style>{'body { background-image: url(""); background-color: white; }'}</style>
              </Helmet>
            </div>

            <div id="lineaCargando" style={{ visibility: mostrarCagando }}>                
              <LinearProgress color="secondary" />
            </div>
                
              <Grid container spacing={1} style={{backgroundColor:'#ffffff'}}>
                
                <Grid item xs={12} sm={12} md={5} style={{height:79, backgroundColor: '#eee'}}>

                  <Typography
                    style = {{ 
                      color: '#b14fc5',
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 600,
                      marginBottom: 4,
                      marginLeft: 1,
                      marginTop: 5                     
                    }}
                  >
                    Ubicación o Prestador
                  </Typography>
                  <Paper
                    style = {{
                      height: 34,
                      width: '90%',
                      marginLeft: 0,
                      boxShadow: 'none'
                    }}
                  >
                    <InputBase      
                        style={{ width:'-webkit-fill-available', fontSize:14 }}                         
                        placeholder=" Ingrese ubicación o prestador a buscar"                                
                        value={ubicacion}                                                          
                        onChange = {
                        (e) => {
                            onUbicacionChange('ubicacion', e.target.value);
                        }}
                        onKeyDown={(e) => teclaPresionadaUbicacion(e)}    
                    />        
                    <IconButton                        
                        style = {{
                        backgroundColor: '#b14fc5',
                        color: '#ffffff',
                        fontSize:20,
                        borderRadius: '0px 5px 5px 0px',
                        padding: 5,
                        position: 'absolute' 
                        }}
                    >
                        <NearMeOutlined/>                   
                    </IconButton>          
                  </Paper>
                </Grid>                
                <Grid item xs={12} sm={6}  md={4} style={{/*height: 0,*/ backgroundColor: '#eee', paddingLeft:5 }} id="gridCategoriasMapa">
                  <Typography
                    style = {{ 
                      color: '#808080',
                      fontSize: 12,
                      fontStyle: 'normal',
                      fontWeight: 400,
                      marginBottom: 4,                                        
                      marginTop: 7
                    }}
                  >
                    Categoría
                  </Typography>
                    
                  <Autocomplete
                    multiple           
                    id="autoCompleteCategoriasMapa"                   
                    style = {{                                        
                      backgroundColor: '#ffffff',
                      width: 220                                        
                    }}                                       
                    value = {
                      valorCategorias ? valorCategorias : [{descripcion: 'cargando ...'}]
                    }
                    onChange = {
                      (event, newValue) => {
                        onCategoriaChange('listaCategoriasCombobox',newValue);
                    }}
                    options = { categorias }
                    getOptionLabel = { option => option.descripcion }                                      
                    defaultValue = { [] }
                    getOptionSelected = { (option, value) => {
                      return option.descripcion === value.descripcion;
                    }}
                    renderOption = { (option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon = { icon }
                          checkedIcon = { checkedIcon }                                           
                          checked = { selected }
                          style = {{                                           
                                          color:'#b14fc5'    
                                            }}                                            
                                          />
                                          {option.descripcion}
                                        </React.Fragment>
                                      )}
                                      renderInput = { (params) => (
                                        <TextField {...params}                                                                                                   
                                                   variant="outlined"
                                                   placeholder="Categorías" 
                                                   style={{ fontSize: '12px !important' }}                                                       
                                        />
                                      )} 
                        />
                        
                    </Grid>
                <Grid item xs={12} sm={6}  md={3} style={{/*height:120,*/ backgroundColor: '#eee', paddingLeft: 5}} id="gridDistanciaMapa">
                  <Typography
                    style={{ 
                      color: '#808080',
                      fontSize: 12,
                      fontStyle: 'normal',
                      fontWeight: 400,
                      marginBottom: 4,                                        
                      marginTop: 7}}
                  >
                    Distancia
                  </Typography>     
                  <Autocomplete                    
                    style={{                                        
                      backgroundColor: '#ffffff',
                      width: 140
                    }}     
                    onChange={ 
                      (e, newValue) => {
                        onDistanciaChange('distancia', newValue);                                  
                    }}        
                      options = {[
                            {descripcion: '2000 Km', distancia: 2000},
                            {descripcion: '1000 Km', distancia: 1000},
                            {descripcion: '500 Km', distancia: 500},
                            {descripcion: '250 Km', distancia: 250},
                            {descripcion: '100 Km', distancia: 100},
                            {descripcion: '50 Km', distancia: 50},
                            {descripcion: '20 Km', distancia: 20},
                            {descripcion: '10 Km', distancia: 10},
                            {descripcion: '5 Km', distancia: 5}
                        ]}             
                        getOptionLabel = { option => option.descripcion }
                        //defaultValue = { [0] }                     
                        renderInput = { (params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder = "Distancia" 
                                style={{fontSize:12}}     
                            />
                        )} 
                    />
                        
                </Grid>
                                              
                <Grid item xs={12} sm={12} md={4} style= {{paddingRight:0}}>
                    <Typography style= {{  
                                    height: 27,
                                    color: '#ffffff',
                                    backgroundColor: '#b14fc5',    
                                    textAlign: 'center',  
                                    paddingTop: 8,          
                                    fontSize: 11,
                                    fontStyle: 'normal',
                                    fontWeight: 400                                       
                                }}
                    >                               
                        Cantidad de Prestadores: {prestadores.length} 
                    </Typography>

                    <List style={{
                                backgroundColor: '#ffffff',
                                width: '100%',
                                /*minWidth: 324,*/
                                height: 522,                                           
                                overflow: 'auto',
                                marginTop: 5,
                                paddingTop:'0px'
                            }}
                    >
                        {prestadores.map((prestador,i) => (
                            <ListItem key={i}
                                    style={{
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                        paddingBottom: '1px',
                                        paddingTop: '0px'
                                    }} 
                                    onClick={ (e) => showInfo(prestador)}
                            >
                                <Card style={{/*width: 302,*/ borderRadius:0, border: '1px solid #ccc', boxShadow:'none', minWidth:'-webkit-fill-available'}}>
                                    <CardContent style={{paddingBottom:0}}>
                                        <Typography style= {{ 
                                                        color: '#b14fc5',
                                                        fontSize:13,
                                                        fontStyle:'normal',
                                                        fontWeight:400,
                                                        marginBottom:4
                                                    }}
                                        >
                                            {prestador.nombre}
                                        </Typography>
                                        
                                        <Typography style= {{     
                                                        color: '#51493e',                                            
                                                        fontSize:11,
                                                        fontStyle:'normal',
                                                        fontWeight:400
                                                    }}
                                        >
                                            <Icon style={{ fontSize: 10, paddingRight:3 }}>location_on</Icon>
                                            {prestador.address}
                                        </Typography>

                                        <Typography style= {{     
                                                        color: '#51493e',                                              
                                                        fontSize:11,
                                                        fontStyle:'normal',
                                                        fontWeight:400,      
                                                        marginBottom:4,  
                                                        marginLeft:14
                                                    }}
                                        >
                                            {    prestador.location}
                                        </Typography>

                                        <Typography style= {{ 
                                                        color: '#51493e',                                                
                                                        fontSize:11,
                                                        fontStyle:'normal',
                                                        fontWeight:400,
                                                        marginBottom:4
                                                    }}
                                        >
                                            <Icon style={{ fontSize: 10, paddingRight:3 }}>phone_enabled</Icon>
                                            Teléfono: {prestador.telefono}       
                                        </Typography>

                                        <Typography style= {{ 
                                                        color: '#51493e',                                                
                                                        fontSize:11,
                                                        fontStyle:'normal',
                                                        fontWeight:400,
                                                        marginBottom:4
                                                    }}>
                                            <Icon style={{ fontSize: 10, paddingRight:3 }}>alternate_email</Icon>                 
                                            {prestador.email}    
                                        </Typography>

                                        <Typography style= {{  
                                                        color: '#51493e',                                          
                                                        fontSize:11,
                                                        fontStyle:'normal',
                                                        fontWeight:400,
                                                        marginBottom:4
                                                    }}>
                                            <Icon style={{ fontSize: 10, paddingRight:3 }}>local_offer</Icon>             
                                            {prestador.descCat}    
                                        </Typography>

                                        <Typography style= {{    
                                                        color: '#aaaaaa',                                                 
                                                        fontSize:11,
                                                        fontStyle:'italic',
                                                        fontWeight:400,
                                                        marginBottom:4
                                                    }}>
                                            {prestador.valor === 0 ? 'Consultar Valor' : 'Desde $' + prestador.valor }   
                                        </Typography>

                                    </CardContent>
                                    <CardActions>
                                        <Button style= {{    
                                                    backgroundColor: '#b14fc5',       
                                                    color: '#ffffff',
                                                    textTransform: 'initial',                                               
                                                    height: 26,
                                                    width: 74,
                                                    fontSize:12,
                                                    fontStyle:'normal',
                                                    fontWeight:400}}
                                        >
                                            Dirección
                                        </Button>
                                        <Typography style= {{    
                                                        marginLeft: 'auto',
                                                        color: '#51493e',                                                      
                                                        fontSize: 12,
                                                        fontStyle: 'normal',
                                                        fontWeight: 700
                                                    }}>
                                            { prestador.distancia } Km   
                                        </Typography>
                                    </CardActions>
                                </Card>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                                        
                <Grid item xs={12} sm={12} md={8}>
                        <Map zoom={zoom}       
                            google={google}
                            center={center}
                            onClick={onClickMapa}
                            initialCenter={center}          
                            containerStyle={{width:'93%', height:570, maxWidth:605}}       
                        >
                            {prestadores.map(prestador => (
                                <Marker key={prestador.cuponId}
                                        title={prestador.nombre}
                                        name={prestador.nombre}
                                        position={{ lat: prestador.lat, lng: prestador.lng }}
                                        icon={{
                                            url: "logo40.png",
                                            anchor: new google.maps.Point(12,8),
                                            scaledSize: new google.maps.Size(40,42)
                                          }}
                                          onClick={ (e) => showInfoMap(prestador)}                              
                                />
                            ))}

                            <InfoWindow visible={mostrarPrestadorSeleccionado}
                                        position={{
                                        lat: prestadorSeleccionado.lat,
                                        lng: prestadorSeleccionado.lng
                                        }}
                            >
                                <div>
                                    <Card style={{width: 365, borderRadius:0, border: '1px solid #ccc', boxShadow:'none'}}>
                                        <CardContent style={{paddingBottom:0}}>
                                            <Typography style= {{ 
                                                            color: '#b14fc5',
                                                            fontSize:13,
                                                            fontStyle:'normal',
                                                            fontWeight:400,
                                                            marginBottom:4
                                                        }}
                                            >
                                                {prestadorSeleccionado.nombre}
                                            </Typography>
                                            
                                            <Typography style= {{     
                                                            color: '#51493e',                                            
                                                            fontSize:11,
                                                            fontStyle:'normal',
                                                            fontWeight:400}}
                                            >
                                                <Icon style={{ fontSize: 10, paddingRight:3 }}>location_on</Icon>
                                                {prestadorSeleccionado.address}
                                            </Typography>

                                            <Typography style= {{     
                                                            color: '#51493e',                                              
                                                            fontSize:11,
                                                            fontStyle:'normal',
                                                            fontWeight:400,      
                                                            marginBottom:4,  
                                                            marginLeft:14
                                                        }}
                                            >
                                                {    prestadorSeleccionado.location}
                                            </Typography>

                                            <Typography style= {{ 
                                                            color: '#51493e',                                                
                                                            fontSize:11,
                                                            fontStyle:'normal',
                                                            fontWeight:400,
                                                            marginBottom:4}}
                                            >
                                                <Icon style={{ fontSize: 10, paddingRight:3 }}>phone_enabled</Icon>
                                                Teléfono: {prestadorSeleccionado.telefono}       
                                            </Typography>

                                            <Typography style= {{ 
                                                            color: '#51493e',                                                
                                                            fontSize:11,
                                                            fontStyle:'normal',
                                                            fontWeight:400,
                                                            marginBottom:4}}>
                                                <Icon style={{ fontSize: 10, paddingRight:3 }}>alternate_email</Icon>                 
                                                {prestadorSeleccionado.email}    
                                            </Typography>

                                            <Typography style= {{  
                                                            color: '#51493e',                                          
                                                            fontSize:11,
                                                            fontStyle:'normal',
                                                            fontWeight:400,
                                                            marginBottom:4}}>
                                                <Icon style={{ fontSize: 10, paddingRight:3 }}>local_offer</Icon>             
                                                {prestadorSeleccionado.descCat}    
                                            </Typography>

                                            <Typography style= {{    
                                                            color: '#aaaaaa',                                                 
                                                            fontSize:11,
                                                            fontStyle:'italic',
                                                            fontWeight:400,
                                                            marginBottom:4}}>
                                                {prestadorSeleccionado.valor === 0 ? 'Consultar Valor' : 'Desde $' + prestadorSeleccionado.valor }   
                                            </Typography>

                                        </CardContent>
                                        <CardActions>
                                            <Button style= {{    
                                                        backgroundColor: '#b14fc5',       
                                                        color: '#ffffff',
                                                        textTransform: 'initial',                                               
                                                        height: 26,
                                                        width: 74,
                                                        fontSize:12,
                                                        fontStyle:'normal',
                                                        fontWeight:400
                                                    }}
                                            >
                                                Dirección
                                            </Button>
                                            <Typography style= {{    
                                                            marginLeft: 'auto',
                                                            color: '#51493e',                                                      
                                                            fontSize: 12,
                                                            fontStyle: 'normal',
                                                            fontWeight: 700
                                                        }}>
                                                { prestadorSeleccionado.distancia } Km   
                                            </Typography>
                                        </CardActions>
                                    </Card>
                                </div>
                            </InfoWindow>
                        </Map>
                    </Grid>                    
                </Grid>                 
            </React.Fragment>
        );
    }
 }


export default GoogleApiWrapper({
    apiKey: "AIzaSyCnQRlBWiutReD-ovzJPh_f-STUBxvMjRY"
 })(MapaBase);

