import React, { Component } from 'react'
import PrestadoresApi from "../../services/PrestadoresAPI";
import TablaBase from "../../components/TablaBase";


class ListarPrestadores extends Component {

    constructor(props) {
        super(props)      
              
        this.state = {
            data: [],
            tipoAlert: "",
            mostarAlert: false,
            mensajeAlert: "",
            mostrarBackDrop:false
        }  

        this.onGetRegistros = this.onGetRegistros.bind(this);  
        this.onClickAgregar = this.onClickAgregar.bind(this);
        this.onClickEditar = this.onClickEditar.bind(this);
        this.onComboboxChange = this.onComboboxChange.bind(this);
        this.onNoLogin = this.onNoLogin.bind(this);
    }

    componentDidMount() {
        this.onGetRegistros();        
    }

    onGetRegistros() {

        var prestadoresApi = new PrestadoresApi();
        prestadoresApi.getPrestadores()        
            .then((res) => {       
                this.setState({data: res.data})
            })
            .catch((error) => {
                console.log(error);            
            });   
    }

    onClickAgregar() {      
        this.props.history.push('/agregar');
    }

    onClickEditar(cuponId) {    
        window.localStorage.setItem("cuponId", cuponId);
        this.props.history.push('/editar');
    }

    onComboboxChange(rowData, id) {

        let dataUpdate =this.state.data;
        const index = id;
        rowData.estado = rowData.estado === 'Activo' ? 'Inactivo' : 'Activo';
        dataUpdate[index] = rowData;
        this.setState({data:dataUpdate});

        var prestadoresApi = new PrestadoresApi();
        prestadoresApi.putEstadoPrestador(rowData.cuponId, rowData.estado)        
            .then((res) => {  
                console.log('Ok');        
            })
            .catch((error) => {
                console.log(error);            
            });   
    }

    onNoLogin()
    {
        this.props.history.push('/');
    }

    render() {

        const {
            data,
            tipoAlert,
            mostarAlert,
            mensajeAlert,
            mostrarBackDrop
        } = this.state;

        return (       
            <TablaBase             
                onClickAgregar = {this.onClickAgregar}
                onClickEditar = {this.onClickEditar}                
                onComboboxChange = {this.onComboboxChange}
                onNoLogin = { this.onNoLogin }  
                data = {data}
                tipoAlert = { tipoAlert }
                mostarAlert = { mostarAlert } 
                mensajeAlert = { mensajeAlert }
                mostrarBackDrop = { mostrarBackDrop }
            />           
        );
    }
}


export default ListarPrestadores;