import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Moment from 'moment';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

class TablaBase extends Component {

    constructor(props){
        super(props);

        this.exportToCSV = this.exportToCSV.bind(this);
    }

    exportToCSV (csvData, fileName) {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    render() {        

        const {   
            data,
            onClickAgregar,
            onClickEditar,           
            onComboboxChange,
            onNoLogin
        } = this.props;

        let registros = data;     
        
        let registrosParaElExcel = registros.map(x => ({           
            
            "Código": x.cuponId,
            "Nombre": x.nombre,
            "Provincia": x.province,
            "Localidad": x.location,
            "Dirección": x.address,
            "Email": x.email ?? "",
            "Telefono": x.telefono ?? "",
            "Valor": x.valor,
            "Porcentaje": x.porcentaje,
            "Estado": x.estado,
            "Fecha Creación": Moment(x.fechaCreacion).format('DD-MM-YYYY HH:mm:ss'),
            "Fecha modificación": x.fechaModificacion,
            "Usuario modificación": x.usuarioModificacion ?? "",
            "CARDIOLOGIA": x.descCat.indexOf("CARDIOLOGIA") !== -1 ? "X" : "",
            "CIRUGIA":
                       x.descCat.indexOf("CIRUGIA,") !== -1 ||
                       (x.descCat.indexOf("CIRUGIA") === 0 && x.descCat.length === 7) ||
                       (x.descCat.indexOf("CIRUGIA") > 1 && x.descCat.indexOf("CIRUGIA") + 7 === x.descCat.length) 
                       ? "X" : "",
            "CLINICA MEDICA": x.descCat.indexOf("CLINICA MEDICA") !== -1 ? "X" : "",
            "DERMATOLOGIA": x.descCat.indexOf("DERMATOLOGIA") !== -1 ? "X" : "",
            "DIAGNOSTICO POR IMAGENES": x.descCat.indexOf("DIAGNOSTICO POR IMAGENES") !== -1 ? "X" : "",
            "ECOGRAFIA": x.descCat.indexOf("ECOGRAFIA") !== -1 ? "X" : "",
            "FONOAUDIOLOGIA": x.descCat.indexOf("FONOAUDIOLOGIA") !== -1 ? "X" : "",
            "GASTROENTEROLOGIA": x.descCat.indexOf("GASTROENTEROLOGIA") !== -1 ? "X" : "",
            "GINECOLOGIA": x.descCat.indexOf("GINECOLOGIA") !== -1 ? "X" : "",
            "INTERNACION": x.descCat.indexOf("INTERNACION") !== -1 ? "X" : "",
            "KINESIOLOGIA": x.descCat.indexOf("KINESIOLOGIA") !== -1 ? "X" : "",
            "LABORATORIO": x.descCat.indexOf("LABORATORIO") !== -1 ? "X" : "",
            "NUTRICION": x.descCat.indexOf("NUTRICION") !== -1 ? "X" : "",
            "OBSTETRICIA": x.descCat.indexOf("OBSTETRICIA") !== -1 ? "X" : "",
            "ODONTOLOGIA": x.descCat.indexOf("ODONTOLOGIA") !== -1 ? "X" : "",
            "OFTALMOLOGIA": x.descCat.indexOf("OFTALMOLOGIA") !== -1 ? "X" : "",
            "OTORRINOLARINGOLOGIA": x.descCat.indexOf("OTORRINOLARINGOLOGIA") !== -1 ? "X" : "",
            "PEDIATRIA": x.descCat.indexOf("PEDIATRIA") !== -1 ? "X" : "",
            "PSICOLOGIA": x.descCat.indexOf("PSICOLOGIA") !== -1 ? "X" : "",
            "PSIQUIATRIA": x.descCat.indexOf("PSIQUIATRIA") !== -1 ? "X" : "",
            "TRAUMATOLOGIA": x.descCat.indexOf("TRAUMATOLOGIA") !== -1 ? "X" : "",
            "UROLOGIA" : x.descCat.indexOf("UROLOGIA") !== -1 ? "X" : "",
            "CIRUGIA PLASTICA" : x.descCat.indexOf("CIRUGIA PLASTICA") !== -1 ? "X" : "",
            "NEUROCIRUGIA" : x.descCat.indexOf("NEUROCIRUGIA") !== -1 ? "X" : "",
            "HEMATOLOGIA" : x.descCat.indexOf("HEMATOLOGIA") !== -1 ? "X" : "",
            "GERIATRIA" : x.descCat.indexOf("GERIATRIA") !== -1 ? "X" : "",
            "HEPATOLOGIA" : x.descCat.indexOf("HEPATOLOGIA") !== -1 ? "X" : "",
            "ONCOLOGIA" : x.descCat.indexOf("ONCOLOGIA") !== -1 ? "X" : "",
            "PROCTOLOGIA" : x.descCat.indexOf("PROCTOLOGIA") !== -1 ? "X" : "",
            "TOMOGRAFIA" : x.descCat.indexOf("TOMOGRAFIA") !== -1 ? "X" : "",
            "RESONANCIA" : x.descCat.indexOf("RESONANCIA") !== -1 ? "X" : "",
            "RADIOGRAFIA" : x.descCat.indexOf("RADIOGRAFIA") !== -1 ? "X" : ""
        }));

        const year = (new Date().getFullYear()).toString();
        const month = (new Date().getMonth() + 1);
        const day = (new Date().getDate());
        const monthString = (month < 10 ? '0' : '') +  month.toString() ;  
        const dayString = (day < 10 ? '0' : '') +  day.toString() ;  

        const nombreArchivo = 'Lista_Prestadores_' + dayString + '-' + monthString + '-' + year;
                      
        if (window.localStorage.getItem("usuarioLogueado") === "true")
        {                  
            return(  
                <React.Fragment>
                                       
                    <MaterialTable    
                        title = {
                            <Chip 
                                style = {{ 
                                    paddingLeft: '1px',
                                    fontSize:'20px',
                                    backgroundColor: '#ffffff',
                                    color: '#7a2682'
                                }}
                                avatar = { 
                                    <Avatar
                                        style = {{ width:'20px', height:'20px' }}
                                        alt = "logo jelper"
                                        src="logo.png" />
                                }
                                label = "Lista Prestadores" /> 
                        }                                       
                        style={{ marginTop: 15, marginBottom: 5 }}
                        columns={[
                        {
                            title: 'Código',
                            field: 'cuponId',
                            type: 'numeric',                                             
                            width: 50
                        },
                        {
                            title: 'Nombre',
                            field: 'nombre',                                             
                            width: 150
                        },
                        {
                            title: 'Provincia',
                            field: 'province',                                             
                            width: 150
                        },
                        {
                            title: 'Localidad',
                            field: 'location',                                             
                            width: 150
                        },
                        {
                            title: 'Dirección',
                            field: 'address',                                             
                            width: 150
                        },
                        {
                            title: 'Activo',
                            field: 'estado',                    
                            render: rowData =>
                                        <FormControl component="fieldset" >                                       
                                            <FormGroup>
                                                <FormControlLabel
                                                    control = {
                                                        <Checkbox                                                            
                                                            checked = { rowData.estado === 'Activo' ? true : false }
                                                            onChange = { (e) => onComboboxChange(rowData, rowData.tableData.id) }
                                                            style={{color: '#7a2682'}}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />}/>
                                            </FormGroup>
                                        </FormControl> 
                        }                
                    ]}
                    data = { data }
                    localization = {{
                        body: {
                            emptyDataSourceMessage: 'No se encontraron registros',
                            addTooltip: "Agregar Prestador",
                            editTooltip: "Editar Prestador",    
                        },
                        toolbar: {
                            searchTooltip: 'Filtra los registros por el texto ingresado',
                            searchPlaceholder: 'Buscar'
                        },
                        pagination: {
                            labelRowsSelect: 'Registros',
                            labelDisplayedRows: ' {from}-{to} de {count}',
                            firstTooltip: 'Primera Página',
                            previousTooltip: 'Página Anterior',
                            nextTooltip: 'Página Siguiente',
                            lastTooltip: 'Ultima Página'
                        },
                        header: {
                            actions: 'Editar',
                            headerStyle: {
                                backgroundColor: '#DBFFD6',
                                fontWeight: 'bold'
                            },
                            hidden: true
                        }
                    }}
                    options = {{  
                        toolbar: true,
                        search: true,
                        actionsColumnIndex: -1,
                        rowStyle: (rowData, i) => {
                            if ((i % 2) === 1) {
                                return { backgroundColor: '#EEE' };
                            }

                            return {};
                        },
                        headerStyle: {
                            backgroundColor: '#E6AFD6',
                            fontWeight: 'bold'
                        }                 
                    }}
                    actions = {[
                        {
                            icon: 'edit',
                            tooltip: 'Editar Prestador',
                            iconProps: { style: { fontSize: "25px", color: "#7a2682" } },
                            onClick: (event, rowData) => onClickEditar(rowData.cuponId)
                        }
                    ]}
                    components={{
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <div style={{padding: '0px 0px 20px 15px'}}>
                                    <Chip 
                                        label="Agregar Prestador"
                                        style={{paddingLeft: '1px', backgroundColor: '#7a2682', color: '#ffffff'}}
                                        placeholder="Agregar Prestador"                                  
                                        icon={<AddCircleOutlineIcon style={{color: '#ffffff'}}/>}  
                                        onClick={(event) => onClickAgregar()}
                                        />      
                                    <Chip 
                                        clickable                                                
                                        style={{position: 'absolute', right: 15, width:'40px'}}
                                        placeholder="Descargar lista de prestadores"  
                                        avatar = { 
                                            <Avatar
                                                style = {{ paddingLeft: '10px', width:'30px', height:'30px' }}
                                                alt = "Descarga Lista Prestadores"
                                                src="iconoExcel.png" />
                                        }   
                                        onClick={(e) => this.exportToCSV(registrosParaElExcel,nombreArchivo)}                                         
                                    />
                                </div>
                            </div>
                        )
                    }}/>

                </React.Fragment> 
                );

        }
        else
        {
            return (
                <div>
                    <div style={{color:'#ffffff'}}>Es necesario estar logueado para acceder a esta pagina</div>
                    <br/>
                    <br/>
                    <Button                     
                        variant = "contained"
                        style = {{ backgroundColor: '#7a2682', color:'#ffffff' }}
                        onClick = { onNoLogin } >
                            Ir a Login
                    </Button>  
                </div>         
            );
        }
        
    }
}

export default TablaBase;