import React, {Component} from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ListarQRPrestadores from "./containers/prestador/ListarQRPrestadores";
import ListarPrestadores from "./containers/prestador/ListarPrestadores";
import AgregarPrestador from "./containers/prestador/AgregarPrestador";
import EditarPrestador from "./containers/prestador/EditarPrestador";
import LoginUsuario from "./containers/usuario/LoginUsuario";
import MapaPrestadores from "./containers/prestador/MapaPrestadores";


class App extends Component {

    render() {
      return (              
          <React.Fragment>           
            <Container maxWidth="md">
              <Router>
               <Switch>
                    {<Route path="/" exact component={LoginUsuario} />}
                    <Route path="/prestadores" component={ListarPrestadores} />
                    <Route path="/agregar" component={AgregarPrestador} />
                    <Route path="/editar" component={EditarPrestador} />
                    <Route path="/listar" component={ListarQRPrestadores} />
                    <Route path="/mapa" component={MapaPrestadores} />
                </Switch>
              </Router>     
            </Container>  
          </React.Fragment>      
      );
    }
}

export default App;