import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import QRCode from 'qrcode.react';
import Popover from '@material-ui/core/Popover';
import MaterialTable from 'material-table';
import Moment from 'moment';
import TimelineIcon from '@material-ui/icons/Timeline';


class EditarBase extends Component{

    render() {
                
        const {            
            titulo,                    
            onGrabarClick,
            onCampoChange,
            onChangeCombobox,
            onVolverClick,     
            onCerrarAlertClick,  
            onNoLogin,  
            onClickDownloadQR,    
            onVerHistoricoClick,
            onCerrarPopUp,      
            onChangeCheckbox,   
            cuponId, 
            nombre,     
            nombreImagen,
            codigoQR,
            cartillaOrigenes,
            cartillaOrigenCombobox,
            address,           
            locations,
            locationCombobox,
            lat,
            lng,
            estado,
            descripcion,
            image,
            image2,
            tags,
            legales,
            valor,
            valorFechaDesde,            
            categorias,
            listaCategoriasCombobox,
            telefono,
            email,
            emailAdministrativo,
            porcentaje,
            informarapp,
            dataModificaciones,
            fechaCreacion,
            anchorEl,
            fechaModificacion,
            usuarioModificacion,
            tipoAlert,
            mostarAlert,
            mensajeAlert,
            ocultarTodo,
            ocultarBotonEditar,
            ocultarBotonVolver,
            mostrarBackDrop,
            deshabilitarHistorico                   
        } = this.props;
           
        let valorInicialCartillaOrigenes;        
        if (cartillaOrigenes.length > 0)
        {                   
            valorInicialCartillaOrigenes = cartillaOrigenCombobox;            
        }

        let valorInicialLocalidades;        
        if (locations.length > 0)
        {                   
            valorInicialLocalidades = locationCombobox;            
        }

        let valorCategorias;           
        if (categorias.length > 0)
        {              
            valorCategorias = listaCategoriasCombobox;            
        }  
        
        let checkEstado;
        checkEstado = false;

        if (estado) 
        {
            if (estado === 'Activo')
            {
                checkEstado = true;
            }            
        }

        if (window.localStorage.getItem("usuarioLogueado") === "true")
        {        
            const open = Boolean(anchorEl);   

            return(
                <div style={styleForm}>
                    
                    <Typography variant="h4" style={style} disabled={ocultarTodo}>
                        {titulo}
                    </Typography>

                    <div style={style}>          
                        <a onClick={ () => onClickDownloadQR(cuponId, nombreImagen) }
                            href={'#_'} target="_self">                   
                            <QRCode 
                                style = {{ width: 100, height: 100 }}
                                id = { cuponId }
                                value = { codigoQR }                                
                                size = { 6000 }
                                fgColor = '#7a2682'
                                bgColor = '#ffffff'
                                level = 'L'
                                renderAs = 'canvas'
                                includeMargin = { true }
                                imageSettings = {{                                  
                                    src: 'logo.png',
                                    height: 834,
                                    width: 800,
                                    x: null,
                                    y: null,
                                    excavate: false                                       
                                }}
                            /> 
                        </a>                     
                    </div>
                
                <ValidatorForm                    
                    ref="form"                    
                    onSubmit = { onGrabarClick }
                    onError = { errors => console.log('ERROR editando prestador', errors) }>

                    <TextValidator
                        disabled = { ocultarTodo }
                        readOnly       
                        placeholder="Código Prestador"
                        fullWidth
                        margin="normal"
                        name="Código Prestador"
                        label="Código Prestador"
                        value = { cuponId }  
                    />

                    <TextField
                        readOnly
                        disabled = { deshabilitarHistorico }
                        id="datetime-local"
                        label="Fecha ultima modificación"
                        type="datetime-local"                          
                        value = { Moment(fechaModificacion).format("YYYY-MM-DDTHH:mm") }   
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style = {{
                            marginTop: '17px',
                            marginBottom: '10px',
                            width:'33%'
                        }}   
                    />

                    <TextValidator
                        readOnly
                        disabled = { deshabilitarHistorico }       
                        placeholder="Usuario ultima modificación"                        
                        margin="normal"
                        name="usuarioModificacion"
                        label="Usuario ultima modificación"
                        value={usuarioModificacion}      
                        style = {{
                            position: 'relative',
                            marginLeft: '10px',
                            width:'33%'
                        }}                       
                    />       
                    <div style={{display:'inline',  width:'33%'}}>
                        <Button
                            disabled = { deshabilitarHistorico }
                            aria-describedby = { cuponId }
                            variant = "contained"
                            color = "primary"
                            onClick = {(e) => onVerHistoricoClick(e) }
                            startIcon={<TimelineIcon />}
                            style = {{
                                color:'#ffffff',
                                marginTop: '15px',
                                marginLeft: '55px',
                                backgroundColor: '#7a2682'
                            }} >
                            Ver Historico
                        </Button>
                        <Popover
                            id = { cuponId }
                            open = { open }
                            anchorEl = { anchorEl }
                            onClose = { onCerrarPopUp }
                            anchorOrigin = {{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin = {{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            style = {{
                                marginTop: '15px',
                                marginLeft: '55px'
                            }}>
                                
                            <MaterialTable                                          
                                style = {{ marginTop: 15, marginBottom: 5 }}
                                columns = {[                            
                                {
                                    title: 'Usuario',
                                    field: 'usuario',                                             
                                    width: 50
                                },
                                {
                                    title: 'Fecha',
                                    field: 'fecha',                                             
                                    width: 200,
                                    render: rowData => <p>{ Moment(rowData.fecha).format("DD-MM-YYYY HH:mm:ss") }</p>                                    
                                },
                                {
                                    title: 'Ip',
                                    field: 'direccionIp',   
                                    type: 'numeric',                                           
                                    width: 50,
                                    align: 'left'
                                }                          
                                ]}
                                data = { dataModificaciones }
                                localization = {{
                                    body: {
                                        emptyDataSourceMessage: 'No se encontraron registros'                            
                                    }   
                                }}
                                options = {{    
                                    search: false,
                                    showTitle: false,
                                    toolbar: false,
                                    paging: false,
                                    rowStyle: (rowData, i) => {
                                        if ((i % 2) === 1) {
                                            return { backgroundColor: '#EEE' };
                                        }
                                        return {};
                                    },
                                    headerStyle: {
                                        backgroundColor: '#E6AFD6',
                                        fontWeight: 'bold'
                                    }                 
                                }}
                            />
                        </Popover>
                    </div>
                    
                    <TextValidator
                        disabled={ocultarTodo}
                        required                                
                        type="text"
                        placeholder="Nombre"
                        fullWidth margin="normal"
                        name="Nombre"
                        label="Nombre"
                        value={nombre}
                        validators={['required']}
                        errorMessages={['Este campo es requerido']}
                        onChange={(e) => onCampoChange('nombre', e.target.value)}
                    />           
                    
                    <TextField
                        readOnly
                        id="datetime-local"
                        label="Fecha creación"
                        type="datetime-local"                          
                        value = { Moment(fechaCreacion).format("YYYY-MM-DDTHH:mm:ss") }   
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style = {{
                            marginTop: '17px',
                            marginBottom: '10px'
                        }}   
                    />
                                  
                    <Autocomplete   
                        disabled={ocultarTodo}
                        fullWidth                  
                        value = { valorInicialCartillaOrigenes ? valorInicialCartillaOrigenes : 'cargando ...'}
                        onChange = {(event, newValue) => {
                            onCampoChange('cartillaOrigenCombobox', newValue);                                  
                        }}        
                        options = {cartillaOrigenes}
                        getOptionLabel = {option => option.descripcion ? option.descripcion : option }   
                        getOptionSelected={(option, value) => {
                            return option.descripcion === value.descripcion;
                        }}
                        filterSelectedOptions                                
                        style = {{
                            marginTop: '17px',
                            marginBottom: '10px'
                        }}             
                        renderInput = { (params) => (
                            <TextValidator
                                {...params}
                                required
                                variant = "standard"
                                label = "Cartilla Orígenes"
                                placeholder = "Cartilla Orígenes" />
                        )}/>               

                    <TextValidator
                        disabled={ocultarTodo}
                        required
                        fullWidth
                        type="text"
                        placeholder="Dirección"
                        margin="normal"
                        name="Dirección"
                        label="Dirección"
                        value={address}
                        validators={['required']}
                        errorMessages={['Este campo es requerido']}
                        onChange={(e) => onCampoChange('address', e.target.value)}/>                                         
      
                    <Autocomplete
                        disabled={ocultarTodo}
                        fullWidth
                        id = "size-small-standard"                               
                        value = { valorInicialLocalidades ? valorInicialLocalidades : 'cargando ...'}
                        onChange = {(event, newValue) => {
                            onCampoChange('locationCombobox', newValue);                                  
                        }}        
                        options = {locations}
                        getOptionLabel = {option => option.descripcion ? option.descripcion : option }
                        style = {{
                            marginTop: '17px',
                            marginBottom: '10px'
                        }}       
                        getOptionSelected={(option, value) => {
                            return option.descripcion === value.descripcion;
                        }}
                        filterSelectedOptions 
                        renderInput = { (params) => (
                            <TextValidator
                                {...params}
                                required
                                variant="standard"
                                label="Localidades"
                                placeholder="Localidades" />
                        )}/>

                    <TextValidator
                        disabled={ocultarTodo}
                        required
                        fullWidth
                        type="text"
                        placeholder="Latitud"
                        margin="normal"
                        name="Latitud"
                        label="Latitud"
                        value = { lat }
                        validators={['required']}
                        errorMessages={['Este campo es requerido']}
                        onChange = { 
                            (e) => onCampoChange('lat', e.target.value)
                        }
                    />
                    
                    <TextValidator 
                        disabled={ocultarTodo}
                        required
                        fullWidth
                        type="text"
                        placeholder="Longitud"
                        margin="normal"
                        name="Longitud"
                        label="Longitud"
                        value = { lng }
                        validators={['required']}
                        errorMessages={['Este campo es requerido']}
                        onChange = {
                            (e) => onCampoChange('lng', e.target.value)
                        }
                    />
                    
                    <FormControl
                        component = "fieldset"
                        style = {{ marginTop: '17px' }}
                        disabled = { ocultarTodo } >
                        <FormHelperText>Estado</FormHelperText>
                        <FormGroup>
                            <FormControlLabel
                                control = {
                                    <Checkbox 
                                              checked = { checkEstado }
                                              style={{color: '#7a2682'}}
                                              onChange={ (e) => onChangeCombobox('estado', e.target.checked) }
                                              inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                                label = { estado }/>
                        </FormGroup>
                    </FormControl> 

                    <FormControl component="fieldset" style ={{ marginTop: '17px'}} disabled={ocultarTodo}>
                        <FormHelperText>Informar App</FormHelperText>
                        <FormGroup>
                            <FormControlLabel
                                control = {
                                    <Checkbox defaultChecked
                                            checked = { informarapp }
                                            style={{color: '#7a2682'}}
                                            onChange={ (e) => onChangeCheckbox('informarapp', e.target.checked) }
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                                label = { informarapp === true ? "Si" : "No" } />
                        </FormGroup>
                    </FormControl> 

                    <TextField disabled={ocultarTodo}
                               type="text"
                               placeholder="Descripción"
                               fullWidth margin="normal"
                               name="Descripción"
                               label="Descripción"
                               value={descripcion}
                               onChange={(e) => onCampoChange('descripcion', e.target.value)}/>    
                                
                    <TextField disabled={ocultarTodo}
                               type="text"
                               placeholder="Imagen 1"
                               fullWidth margin="normal"
                               name="Imagen 1"
                               label="Imagen Pequeña"
                               value={image}
                               onChange={(e) => onCampoChange('image', e.target.value)}/>

                    <TextField disabled={ocultarTodo}
                               type="text"
                               placeholder="Imagen 2"
                               fullWidth margin="normal"
                               name="Imagen 2"
                               label= "Imagen Grande"
                               value={image2}
                               onChange={(e) => onCampoChange('image2', e.target.value)}/>
 
                    <TextField disabled={ocultarTodo}
                               type="text"
                               placeholder="Tags"
                               fullWidth margin="normal"
                               name="Tags"
                               label="Tags"
                               value={tags}
                               onChange={(e) => onCampoChange('tags', e.target.value)}/>

                    <TextField disabled={ocultarTodo}
                               type="text"
                               placeholder="Legales"
                               fullWidth margin="normal"
                               name="Legales"
                               label="Legales"
                               value={legales}
                               onChange={(e) => onCampoChange('legales', e.target.value)}/>       
            
                    <TextField disabled={ocultarTodo}
                               type="text"
                               placeholder="Valor"
                               fullWidth margin="normal"
                               name="Valor"
                               label="Valor"
                               value={valor}
                               InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                               onChange={(e) => onCampoChange('valor', e.target.value)}/> 

                    <TextField disabled={ocultarTodo}
                               type="number"
                               placeholder="Porcentaje"
                               fullWidth margin="normal"
                               name="Porcentaje"
                               label="Porcentaje"
                               value={porcentaje}
                               InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    min: "0", max: "99", step: "1"
                               }}
                               validators={['required']}
                               errorMessages={['Este campo es requerido']}  
                               onChange={(e) => onCampoChange('porcentaje', e.target.value)}/>

                    <TextField disabled={ocultarTodo}                               
                               label="Valor Fecha Desde"
                               type="date"   
                               style ={{ marginTop: '17px', marginBottom: '10px' }}                       
                               value={valorFechaDesde ? valorFechaDesde : "2017-05-24"}
                               onChange = {(e) => { onCampoChange('valorFechaDesde',e.target.value) }}
                               InputLabelProps={{
                                   shrink: true,
                               }}/>
                    
                   <Autocomplete disabled={ocultarTodo}
                                 id="size-small-standard-multi"
                                 size="small"       
                                 multiple    
                                 style ={{ marginTop: '17px', marginBottom: '10px' }}
                                 value = { valorCategorias ? valorCategorias : [{descripcion: 'cargando ...'}]}
                                 onChange = {(event, newValue) => {
                                    onCampoChange('listaCategoriasCombobox',newValue);
                                 }}
                                 options={categorias}
                                 getOptionLabel={option => option.descripcion}
                                 filterSelectedOptions
                                 defaultValue={[]}
                                 getOptionSelected={(option, value) => {
                                    return option.descripcion === value.descripcion;
                                 }}
                                 fullWidth
                                 renderInput={(params) => (
                                    <TextField {...params} variant="standard" label="Categorías" placeholder="Categorías" />
                                 )} />
                   
                   <TextValidator
                        disabled={ocultarTodo}
                        required
                        fullWidth
                        type="text"
                        placeholder="Teléfono"
                        margin="normal"
                        name="Teléfono"
                        label="Teléfono"
                        value = { telefono }
                        validators = {['required']}
                        errorMessages = {['Este campo es requerido']}
                        onChange = {
                            (e) => onCampoChange('telefono', e.target.value)
                        }
                    /> 

                    <TextValidator
                        disabled={ocultarTodo}                       
                        fullWidth
                        type="text"
                        placeholder="Email"
                        margin="normal"
                        name="Email"
                        label="Email"
                        value = { email }                        
                        onChange = { 
                            (e) => onCampoChange('email', e.target.value)
                        }
                    /> 

                    <TextValidator
                        disabled={ocultarTodo}                        
                        fullWidth
                        type="text"
                        placeholder="Email Administrativo"
                        margin="normal"
                        name="emailAdministrativo"
                        label="Email Administrativo"
                        value = { emailAdministrativo }                        
                        onChange = { 
                            (e) => onCampoChange('emailAdministrativo', e.target.value)
                        }
                    /> 
                
                    <br/>
                    <br/>
                    
                    <Collapse
                        fullWidth
                        in = { mostarAlert }>
                        <Alert severity = { tipoAlert }
                               action = {
                                   <IconButton aria-label="close"
                                               color="inherit"
                                               size="small"
                                               onClick={() => {
                                                   onCerrarAlertClick();    
                                               }}>
                                               <CloseIcon fontSize="inherit" />
                                   </IconButton>
                               }>
                            {mensajeAlert}
                        </Alert>
                    </Collapse>

                    <br/>
                    <br/>

                    <Button disabled = {ocultarBotonEditar}
                            variant="contained"
                            style={{backgroundColor: '#7a2682', color:'#ffffff'}}
                            type="submit">
                        Editar
                    </Button>

                    <Button disabled = {ocultarBotonVolver}
                            variant="contained"
                            color="secundary"
                            onClick={onVolverClick}>
                        Volver
                    </Button>                    
                </ValidatorForm>

                <Backdrop open = { mostrarBackDrop }>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
        }
        else
        {
            return (
                <div>
                    <div style={{color:'#ffffff'}}>Es necesario estar logueado para acceder a esta pagina</div>
                    <br/>
                    <br/>
                    <Button                     
                        variant = "contained"
                        style = {{ backgroundColor: '#7a2682', color:'#ffffff' }}
                        onClick = { onNoLogin } >
                            Ir a Login
                    </Button>  
                </div>         
            );
        }
    }
}


const style ={
    display: 'flex',
    justifyContent: 'center'
};

const styleForm = {
    padding: '30px',
    backgroundColor: '#ffffff'
}

export default EditarBase;
