import axios from 'axios';

class LocalidadesAPI {
    uriBase = process.env.REACT_APP_URI_BASE;
    endPoint = 'api/Localidades';

    getLocalidades(idLocalidad) {
        return axios.get(this.uriBase + this.endPoint + '/' + idLocalidad);
    }

}

export default LocalidadesAPI;