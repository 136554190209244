import React, { Component } from 'react';
import UsuariosApi from "../../services/UsuariosAPI";
import LoginBase from "../../components/LoginBase";

class LoginUsuario extends Component{

    constructor(props){
        super(props);
        this.state = {
            titulo: 'Ingreso Usuario',
            usuario: '',
            password: '',
            errorIngreso: false,
            errorTexto: ''
        }

        window.localStorage.setItem("usuarioLogueado", false);
        this.onLoguearClick = this.onLoguearClick.bind(this);
        this.onCampoChange = this.onCampoChange.bind(this);
    }

    onLoguearClick() {
   
        let _usuario = {
            usuario: this.state.usuario,
            password: this.state.password
        };

        var usuariosApi = new UsuariosApi();        
        usuariosApi.postLogin(_usuario)
            .then(res => {
                this.setState({
                    errorIngreso: false, 
                    errorTexto: ""
                });
                window.localStorage.setItem("usuarioLogueado", true);
                window.localStorage.setItem("usuario",this.state.usuario);
                this.props.history.push('/prestadores');
            })
            .catch(error => {
                this.setState({
                    errorIngreso: true, 
                    errorTexto: "Usuario o Contraseña invalido."
                });
                console.log('error al loguearse', error);
            });              
    }

    onCampoChange(campo, value) {        
        this.setState({ [campo]: value });
    }

    render() {
        const {
            titulo,
            usuario,
            password,
            errorIngreso,
            errorTexto
        } = this.state;
        
        return(
            <div>
                <LoginBase
                    titulo = { titulo }   
                    onLoguearClick = { this.onLoguearClick }
                    onCampoChange = { this.onCampoChange }
                    usuario = { usuario }
                    password = { password }
                    errorIngreso = { errorIngreso }
                    errorTexto = { errorTexto }
                />
            </div>
        );
    }
}

export default LoginUsuario;
