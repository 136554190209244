import React, { Component } from 'react';
import MaterialTable from 'material-table';
import QRCode from 'qrcode.react';

class TablaQRBase extends Component {

    constructor(props){
        super(props);

        this.onClickDownloadQR = this.onClickDownloadQR.bind(this);
    }
    
    onClickDownloadQR(cuponId, nombreImagen) {
        const canvas = document.getElementById(cuponId);
        const pngUrl = canvas
          .toDataURL("image/jpg")
          .replace("image/jpg", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = nombreImagen;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

    render() {        

        const {   
            data
        } = this.props;
                  
        return(              
            <MaterialTable    
                title = "Lista Prestadores"
                style={{ marginTop: 15, marginBottom: 5 }}
                columns={[
                {
                    title: 'Código',
                    field: 'cuponId',
                    type: 'numeric',                                             
                    width: 50
                },
                {
                    title: 'Nombre',
                    field: 'nombre',                                             
                    width: 150
                },
                {
                    title: 'Codigo QR',
                    field: 'codigoQR',
                    render: rowData =>
                        <div>          
                            <a onClick={ () => this.onClickDownloadQR(rowData.cuponId, rowData.nombreImagen) }
                               href={'#_'} target="_self">                   
                                <QRCode 
                                    style = {{ width: 50, height: 50 }}
                                    id = { rowData.cuponId }
                                    value = { rowData.codigoQR }                                
                                    size = { 6000 }
                                    fgColor = '#7a2682'
                                    bgColor = '#ffffff'
                                    level = 'L'
                                    renderAs = 'canvas'
                                    includeMargin = { true }
                                    imageSettings = {{                                  
                                        src: 'logo.png',
                                        height: 834,
                                        width: 800,
                                        x: null,
                                        y: null,
                                        excavate: false                                       
                                    }}
                                /> 
                            </a>                              
                        </div>,                                                 
                    width: 200
                }                            
            ]}
            data = { data }
            localization = {{
                body: {
                    emptyDataSourceMessage: 'No se encontraron registros',
                    addTooltip: "Agregar Prestador",
                    editTooltip: "Editar Prestador",    
                },
                toolbar: {
                    searchTooltip: 'Filtra los registros por el texto ingresado',
                    searchPlaceholder: 'Buscar'
                },
                pagination: {
                    labelRowsSelect: 'Registros',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera Página',
                    previousTooltip: 'Página Anterior',
                    nextTooltip: 'Página Siguiente',
                    lastTooltip: 'Ultima Página'
                },
                header: {
                    actions: 'Editar',
                    headerStyle: {
                        backgroundColor: '#DBFFD6',
                        fontWeight: 'bold'
                    },
                    hidden: true
                }
            }}
            options = {{
                search: true,
                actionsColumnIndex: -1,
                rowStyle: (rowData, i) => {
                    if ((i % 2) === 1) {
                        return { backgroundColor: '#EEE' };
                    }

                    return {};
                },
                headerStyle: {
                    backgroundColor: '#E6AFD6',
                    fontWeight: 'bold'
                }                 
            }}                            
        />);  
    }
}

export default TablaQRBase;