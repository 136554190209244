import axios from 'axios';

class PrestadoresApi {
    uriBase = process.env.REACT_APP_URI_BASE;
    uriBaseMapa = process.env.REACT_APP_URI_BASE_MAPA;
    endPoint = 'api/Prestadores';

    getPrestadores() {
        return axios.get(this.uriBase + this.endPoint + '/listaFront'); 
    }

    getPrestador(cuponId) {
        return axios.get(this.uriBase + this.endPoint + '/lista/' + cuponId); 
    }
    
    deletePrestador(cuponId) {
        return axios.delete(this.uriBase + this.endPoint + '/' + cuponId);
    }

    putPrestador(prestador) {
        return axios.put(this.uriBase + this.endPoint, prestador);
    }

    putEstadoPrestador(cuponId,estado) {
        return axios.put(this.uriBase + this.endPoint + '/estado/' + cuponId + '/' + estado );
    }

    postPrestador(prestador) {
        return axios.post(this.uriBase + this.endPoint, prestador);
    } 

    postBuscarPrestadores(parametros) {
        return axios.post(this.uriBase + this.endPoint + '/buscar', parametros);
    } 

    postBuscarPrestadoresMapa(parametros) {
        return axios.post(this.uriBaseMapa + 'Prestadores/buscar', parametros);
    } 

}

export default PrestadoresApi;
