import axios from 'axios';

class PrestadorModificacionesAPI {

    uriBase = process.env.REACT_APP_URI_BASE;
    endPoint = 'api/PrestadorModificaciones';

    getPrestadorModificaciones(idPrestador) {
        return axios.get(this.uriBase + this.endPoint + '/' + idPrestador );
    }

    postPrestadorModificaciones(prestadorModificaciones) {   
        return axios.post(this.uriBase + this.endPoint, prestadorModificaciones);
    }

}

export default PrestadorModificacionesAPI;