import React, { Component } from 'react';
import CartillaOrigenesAPI from '../../services/CartillaOrigenesAPI';
import PrestadoresApi from "../../services/PrestadoresAPI";
import LocalidadesAPI from "../../services/LocalidadesAPI";
import CategoriasAPI from "../../services/CategoriasAPI";
import AgregarBase from "../../components/AgregarBase";


class AgregarPrestador extends Component{

    constructor(props){
        super(props);

        this.state = {
            titulo: 'Agregar Prestador',
            cuponId: null, 
            nombre: '',
            idCartillaOrigen: 0,
            cartillaOrigenes:[],
            cartillaOrigenCombobox:[],
            address: '',
            idLocation: 0,
            locations: [],
            locationCombobox: [],
            lat: null,
            lng: null,
            estado: 'Activo',
            informarapp:true,
            descripcion: '',
            image: "https://www.jelper.com.ar/img/repo/Prestador.jpg",
            image2: '',
            tags: '',
            legales: '',
            valor: 0,
            valorFechaDesde: '',
            fechaHoy: new Date().toLocaleString(),
            idCategorias: [],
            categorias: [],
            listaCategoriasCombobox: [],
            telefono: '',
            email: '',
            emailAdministrativo: '',
            porcentaje: 0,
            tipoAlert:"",
            mostarAlert: false,
            mensajeAlert: "",
            ocultarTodo: false,
            ocultarBotonAgregar: true,
            ocultarBotonVolver: false,
            mostrarBackDrop:false
        }

        this.onGetPrestador = this.onGetPrestador.bind(this);
        this.onGrabarClick = this.onGrabarClick.bind(this);
        this.onCampoChange = this.onCampoChange.bind(this);
        this.onChangeCombobox = this.onChangeCombobox.bind(this);
        this.onVolverClick = this.onVolverClick.bind(this);
        this.onCerrarAlertClick = this.onCerrarAlertClick.bind(this);
        this.onNoLogin = this.onNoLogin.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    }

    componentDidMount() {       

        const year = (new Date().getFullYear()).toString();
        const month = (new Date().getMonth() + 1);
        const day = (new Date().getDate());
        const monthString = (month < 10 ? '0' : '') +  month.toString() ;  
        const dayString = (day < 10 ? '0' : '') +  day.toString() ;  

        this.setState({valorFechaDesde: year + '-' + monthString + '-' + dayString }); 

        this.onGetPrestador(1);    
    }

    onGetPrestador(cuponId) {      
            var localidadesAPI = new LocalidadesAPI();
            localidadesAPI.getLocalidades(1)
                .then(res => {                
                    this.setState({locations: res.data.localidades,
                                locationCombobox: res.data.locationCombobox});        
                })
                .catch(error => {
                    console.log('ERROR obteniendo localidades', error);
                });   

            var categoriasAPI = new CategoriasAPI();        
            categoriasAPI.postCategorias([1])
                .then(res => {                
                    this.setState({ ocultarBotonAgregar: false,
                                    categorias: res.data.categorias,
                                    listaCategoriasCombobox: res.data.listaCategoriasCombobox});        
                })
                .catch(error => {
                    console.log('ERROR obteniendo categorias', error);
                });    
        
        var cartillaOrigenesAPI = new CartillaOrigenesAPI();
        cartillaOrigenesAPI.getCartillaOrigenes(1)
                .then(res => {         
                    this.setState({
                        cartillaOrigenes: res.data.cartillaOrigenes,
                        cartillaOrigenCombobox: res.data.cartillaOrigenCombobox});  
                })
                .catch(error => {
                    console.log('ERROR obteniendo cartilla', error);
                });
                  
    }

    onGrabarClick() {
       
        this.setState({ 
            ocultarTodo: true,
            ocultarBotonAgregar: true,
            ocultarBotonVolver: true,
            mostrarBackDrop: true });

        let _prestador = {
            cuponId: this.state.cuponId, 
            nombre: this.state.nombre,
            idCartillaOrigen: this.state.idCartillaOrigen,
            cartillaOrigen: this.state.cartillaOrigenCombobox,
            address: this.state.address,
            idLocation: this.state.idLocation,
            location: this.state.locationCombobox,
            lat: parseFloat(this.state.lat),
            lng: parseFloat(this.state.lng),
            estado: this.state.estado,
            descripcion: this.state.descripcion,
            image: this.state.image,
            image2: this.state.image2,
            tags: this.state.tags,
            legales: this.state.legales,
            valor: parseInt(this.state.valor ?? 0, 10),
            valorFechaDesde: this.state.valorFechaDesde,            
            telefono: this.state.telefono,
            email: this.state.email,
            emailAdministrativo: this.state.emailAdministrativo,
            idCategorias: this.state.idCategorias,
            listaCategorias: this.state.listaCategoriasCombobox,
            porcentaje: parseFloat(this.state.porcentaje),
            informarapp: this.state.informarapp
        };

        var prestadoresApi = new PrestadoresApi();        
        prestadoresApi.postPrestador(_prestador)
            .then(res => {
                this.setState({
                    tipoAlert: "success",
                    mostarAlert: true,
                    mensajeAlert: "El Prestador se ha agregado satisfactoriamente",
                    ocultarTodo: false,
                    ocultarBotonAgregar: false,
                    ocultarBotonVolver: false,
                    mostrarBackDrop: false });
            })
            .catch(error => {  

                let _tipoAlert; 
                let _mensaje;  

                if (error.response) {
                    if (error.response.status === 400) {
                        _tipoAlert = "error";
                    } else {
                        _tipoAlert = "warning";
                    }
                    _mensaje = error.response.status + ' - ' + error.response.data.mensaje;
                } else if (error.request) {
                    _tipoAlert = "error";
                    _mensaje = "Error al intentar agregar prestador";
                } else {      
                    _tipoAlert = "error";  
                    _mensaje = error.message;                
                }                

                this.setState({ tipoAlert: _tipoAlert,
                                mostarAlert: true,
                                mensajeAlert: _mensaje,
                                ocultarTodo: false,
                                ocultarBotonAgregar: false,
                                ocultarBotonVolver: false,
                                mostrarBackDrop: false  });
        });              
    }

    onCampoChange(campo, value) {        
        this.setState({ [campo]: value });
    }

    onChangeCombobox(campo, checked) {

        if (checked) 
        {
            this.setState({ [campo]: 'Activo' });
        }
        else
        {
            this.setState({ [campo]: 'Inactivo' });
        }
    }

    onChangeCheckbox(campo, checked) {
        this.setState({ [campo]: checked });      
    }

    onVolverClick() {
        this.props.history.push('/prestadores');
    }

    onCerrarAlertClick() {
        this.setState({ mostarAlert:false });
        this.props.history.push('/prestadores');
    }

    onNoLogin()
    {
        this.props.history.push('/');
    }

    render() {
        const {
            titulo,
            cuponId, 
            nombre,
            idCartillaOrigen,
            cartillaOrigenes,
            cartillaOrigenCombobox,
            address,
            idLocation,
            locations,
            locationCombobox,
            lat,
            lng,
            estado,
            descripcion,
            image,
            image2,
            tags,
            legales,
            valor,
            valorFechaDesde,
            idCategorias,
            categorias,
            listaCategoriasCombobox,
            telefono,
            email,
            emailAdministrativo,
            porcentaje,
            informarapp,
            tipoAlert,
            mostarAlert,
            mensajeAlert,
            ocultarBotonAgregar,
            ocultarBotonVolver,
            mostrarBackDrop
        } = this.state;
        
        return(
            <div>
                <AgregarBase
                    titulo = { titulo }                    
                    onGrabarClick = { this.onGrabarClick }
                    onCampoChange = { this.onCampoChange }       
                    onChangeCombobox = { this.onChangeCombobox }   
                    onVolverClick = { this.onVolverClick }    
                    onCerrarAlertClick = { this.onCerrarAlertClick }    
                    onNoLogin = { this.onNoLogin }   
                    onChangeCheckbox = { this.onChangeCheckbox }  
                    cuponId = { cuponId }
                    nombre = { nombre }
                    idCartillaOrigen = { idCartillaOrigen }
                    cartillaOrigenes  = { cartillaOrigenes }
                    cartillaOrigenCombobox = { cartillaOrigenCombobox }
                    address = { address }
                    idLocation = { idLocation }
                    locations = { locations }
                    locationCombobox = { locationCombobox }
                    lat = { lat }
                    lng = { lng }
                    estado = { estado }
                    descripcion = { descripcion }
                    image = { image }
                    image2 = { image2 }
                    tags = { tags }
                    legales = { legales }
                    valor = { valor }
                    valorFechaDesde = { valorFechaDesde }                    
                    idCategorias = { idCategorias }
                    categorias = { categorias }
                    listaCategoriasCombobox = { listaCategoriasCombobox }
                    telefono = { telefono }
                    email = { email }
                    emailAdministrativo = { emailAdministrativo }
                    porcentaje = { porcentaje }
                    informarapp = { informarapp }
                    tipoAlert = { tipoAlert }
                    mostarAlert = { mostarAlert } 
                    mensajeAlert = { mensajeAlert }
                    ocultarBotonAgregar = { ocultarBotonAgregar }
                    ocultarBotonVolver = { ocultarBotonVolver }
                    mostrarBackDrop = { mostrarBackDrop }
                />
            </div>
        );
    }
}

export default AgregarPrestador;
