import React, { Component } from "react";
import MapContainer from "../../components/MapaBase";
import PrestadoresApi from "../../services/PrestadoresAPI";
import CategoriasAPI from "../../services/CategoriasAPI";

class MapaPrestadores extends Component {

    constructor(props) {
      super(props)      
            
      this.state = {
        prestadorSeleccionado: { lat: -34.6195194, lng: -58.4368629 },
        prestadores: [],
        categorias: [],
        listaCategoriasCombobox: [],
        ubicacion: '',
        distancia: 0,        
        miUbicacionLatLng: { latitud: -34.6195194, longitud: -58.4368629 },
        mostrarPrestadorSeleccionado:false,
        centro: { lat: -34.6195194, lng: -58.4368629 },
        mostrarCagando: 'visible'
      };
            
      this.showInfo = this.showInfo.bind(this);
      this.showInfoMap = this.showInfoMap.bind(this);
      this.onClickMapa = this.onClickMapa.bind(this);
      this.onCategoriaChange = this.onCategoriaChange.bind(this);
      this.onDistanciaChange = this.onDistanciaChange.bind(this);
      this.onUbicacionChange = this.onUbicacionChange.bind(this);
      this.onPrestadorChange = this.onPrestadorChange.bind(this);
      this.cargarPrestadores = this.cargarPrestadores.bind(this);
      this.buscarPrestadores = this.buscarPrestadores.bind(this);      
      this.teclaPresionadaUbicacion = this.teclaPresionadaUbicacion.bind(this);
      this.teclaPresionadaPrestador = this.teclaPresionadaPrestador.bind(this);
    }

    componentWillReceiveProps(props)
    {
      console.log('componentWillReceiveProps');
    } 

    componentDidMount() {
      
      /*navigator.geolocation.getCurrentPosition((position) => {       
          this.setState({
            miUbicacionLatLng: 
            {
              latitud: position.coords.latitude,
              longitud: position.coords.longitude
            }
          });

          let parametros = {
            coordenada: {
              latitud: position.coords.latitude,
              longitud: position.coords.longitude
            },  
            ubicacion: '',
            categorias: [],
            distancia: 0
          };  

          this.buscarPrestadores(parametros);     
      });*/

      let parametros = {
        coordenada: {
          latitud: -34.6195194,
          longitud: -58.4368629
        },  
        ubicacion: '',
        categorias: [],
        distancia: 0
      };  
  
      this.buscarPrestadores(parametros);     
    }
    
    showInfo(prestadorSeleccionado)
    {     
      this.setState({
        prestadorSeleccionado: prestadorSeleccionado,
        mostrarPrestadorSeleccionado: true,
        centro: { 
          lat: prestadorSeleccionado.lat,
          lng: prestadorSeleccionado.lng
        }
      });
    }

    showInfoMap(prestadorSeleccionado)
    {           
      this.setState({
        prestadorSeleccionado: prestadorSeleccionado,
        mostrarPrestadorSeleccionado: true
      });
    }

    onClickMapa() {
      this.setState({
        mostrarPrestadorSeleccionado: false
      });
    };
    
    onUbicacionChange(campo, value)
    {   
      this.setState({ [campo]: value,
        mostrarCagando: 'visible' });     

      let parametros = {
        coordenada: this.state.miUbicacionLatLng,  
        ubicacion: value,
        categorias: this.state.listaCategoriasCombobox,
        distancia: this.state.distancia        
      };

      this.buscarPrestadores(parametros);
    }

    onPrestadorChange(campo, value)
    {   
      this.setState({ [campo]: value });     
    }

    onCategoriaChange(campo, value)
    {    
      this.setState({
        [campo]: value,
        mostrarCagando: 'visible'
      });  

      let parametros = {
        coordenada: this.state.miUbicacionLatLng,  
        ubicacion: this.state.ubicacion,
        categorias: value,
        distancia: this.state.distancia
      };

      this.buscarPrestadores(parametros);
    }

    onDistanciaChange(campo, value)
    {    
      let _distancia = 0;

      if (value !== null)
      {
        _distancia = value.distancia;
      }

      this.setState({
        [campo]: _distancia,
        mostrarCagando: 'visible'
      });

      let parametros = {
        coordenada: this.state.miUbicacionLatLng,  
        ubicacion: this.state.ubicacion,
        categorias: this.state.listaCategoriasCombobox,
        distancia: _distancia
      };

      console.log('parametros', parametros);

      this.buscarPrestadores(parametros);
    }

    teclaPresionadaUbicacion(evento)
    {
      if ( evento.keyCode === 13 ) {
        let parametros = {
          coordenada: this.state.miUbicacionLatLng,  
          ubicacion: this.state.ubicacion,
          categorias: this.state.listaCategoriasCombobox,
          distancia: this.state.distancia
        };
  
        this.buscarPrestadores(parametros);
      }
    } 

    teclaPresionadaPrestador(evento)
    {
      if ( evento.keyCode === 13 ) {
        let parametros = {
          coordenada: this.state.miUbicacionLatLng,  
          ubicacion: this.state.ubicacion,
          categorias: this.state.listaCategoriasCombobox,
          distancia: this.state.distancia
        };
  
        this.buscarPrestadores(parametros);
      }
    } 

    cargarPrestadores()
    {
      let parametros = {
        coordenada: this.state.miUbicacionLatLng,  
        ubicacion: this.state.ubicacion,
        categorias: this.state.listaCategoriasCombobox,
        distancia: this.state.distancia
      };

    this.buscarPrestadores(parametros);
   }  

    buscarPrestadores(parametros)
    {                
      var prestadoresApi = new PrestadoresApi();
      prestadoresApi.postBuscarPrestadoresMapa(parametros)
        .then(res => {                
          this.setState({
            prestadores: res.data, 
            mostrarCagando: 'hidden'
          });    
        })
        .catch(error => {
          console.log('ERROR obteniendo categorias',error);
        });  

      var categoriasAPI = new CategoriasAPI();        
      categoriasAPI.postCategoriasMapa([1])
        .then(res => {                
          this.setState({
          categorias: res.data.categorias});        
        })
        .catch(error => {
          console.log('ERROR buscando prestadores', error);
        }); 
    }
    
    render() {
      const {
        centro,
        prestadores,
        categorias,
        listaCategoriasCombobox,
        prestadorSeleccionado,
        ubicacion,        
        mostrarPrestadorSeleccionado,
        mostrarCagando        
      } = this.state;

      return (
        <div>         
          <MapContainer
            center = { centro }
            zoom = { 14 }
            prestadores = { prestadores }
            categorias = { categorias }
            ubicacion = { ubicacion }            
            listaCategoriasCombobox = { listaCategoriasCombobox }
            prestadorSeleccionado = { prestadorSeleccionado }
            mostrarPrestadorSeleccionado = { mostrarPrestadorSeleccionado }
            mostrarCagando = { mostrarCagando }
            showInfo = { this.showInfo }
            showInfoMap = { this.showInfoMap }
            onClickMapa = { this.onClickMapa }
            onCategoriaChange = { this.onCategoriaChange }
            onDistanciaChange = { this.onDistanciaChange }
            onUbicacionChange = { this.onUbicacionChange }
            onPrestadorChange = { this.onPrestadorChange }
            buscarPrestadores = { this.buscarPrestadores }   
            cargarPrestadores = { this.cargarPrestadores }
            teclaPresionadaUbicacion = { this.teclaPresionadaUbicacion }
            teclaPresionadaPrestador = { this.teclaPresionadaPrestador }
          />
        </div>
      );
    }

 }

export default MapaPrestadores;