import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import InputAdornment from '@material-ui/core/InputAdornment';


class AgregarBase extends Component{
    
    render() {
        
        const {
            titulo,                    
            onGrabarClick,
            onCampoChange,   
            onChangeCombobox, 
            onVolverClick,     
            onCerrarAlertClick,    
            onNoLogin,            
            onChangeCheckbox,                
            cuponId, 
            nombre,    
            cartillaOrigenes,           
            address,           
            locations,            
            lat,
            lng,
            estado,
            descripcion,
            image,
            image2,
            tags,
            legales,
            valor,
            valorFechaDesde,            
            categorias,            
            telefono,
            email,
            emailAdministrativo,
            porcentaje,
            informarapp,
            tipoAlert,
            mostarAlert,
            mensajeAlert,
            ocultarTodo,
            ocultarBotonAgregar,
            ocultarBotonVolver,
            mostrarBackDrop                    
        } = this.props;
                
        let checkEstado;
        checkEstado = true;

        if (estado) 
        {
            if (estado === 'Inactivo')
            {
                checkEstado = false;
            }                     
        }

        if (window.localStorage.getItem("usuarioLogueado") === "true")
        {

        return (
            <div style={styleForm}>

                <Typography variant="h4" style={style} disabled={ocultarTodo}>
                    {titulo}
                </Typography>

                <ValidatorForm
                    ref="form"                    
                    onSubmit = { onGrabarClick }
                    onError = { errors => console.log('ERROR agregando prestador', errors) }>    
                            
                <TextValidator
                    disabled={ocultarTodo}
                    required
                    type="number"
                    placeholder="Código Prestador"
                    fullWidth
                    margin="normal"
                    name="Código Prestador"
                    label="Código Prestador"
                    value={cuponId} 
                    validators={['required']}
                    errorMessages={['Este campo es requerido']}       
                    inputProps={{ min: "1", max: "9999", step: "1" }}                       
                    onChange={(e) => onCampoChange('cuponId', parseInt(e.target.value,10))} />      

                <TextValidator
                    disabled={ocultarTodo}
                    required                                
                    type="text"
                    placeholder="Nombre"
                    fullWidth margin="normal"
                    name="Nombre"
                    label="Nombre"
                    value={nombre}
                    validators={['required']}
                    errorMessages={['Este campo es requerido']}
                    onChange={(e) => onCampoChange('nombre', e.target.value)} />    
                                
                <Autocomplete
                    disabled={ocultarTodo}
                    fullWidth                            
                    onChange = { 
                        (e, newValue) => {
                            onCampoChange('cartillaOrigenCombobox', newValue);                                  
                    }}        
                    options = { cartillaOrigenes } 
                    getOptionLabel = {
                        option => option.descripcion ? option.descripcion : option
                    }             
                    style = {{
                        marginTop: '17px',
                        marginBottom: '10px'
                    }}            
                    renderInput = { (params) => (
                        <TextValidator
                            {...params}
                            required
                            variant = "standard"
                            label = "Cartilla Orígenes"
                            placeholder = "Cartilla Orígenes" />
                    )} />

                <TextValidator
                    disabled={ocultarTodo}
                    required
                    fullWidth
                    type="text"
                    placeholder="Dirección"
                    margin="normal"
                    name="Dirección"
                    label="Dirección"
                    value={address}
                    validators={['required']}
                    errorMessages={['Este campo es requerido']}
                    onChange={(e) => onCampoChange('address', e.target.value)} />                  

                <Autocomplete      
                    disabled={ocultarTodo}                  
                    fullWidth       
                    onChange = { 
                        (e, newValue) => {
                            onCampoChange('locationCombobox', newValue);                                  
                    }}        
                    options = { locations }
                    style = {{
                        marginTop: '17px',
                        marginBottom: '10px'
                    }}
                    getOptionLabel = { 
                        option => option.descripcion ? option.descripcion : option
                    }              
                    renderInput = { (params) => (
                        <TextValidator
                            {...params}
                            required
                            variant="standard"
                            label="Localidades"
                            placeholder="Localidades" />
                    )} />

                <TextValidator
                    disabled={ocultarTodo}
                    required
                    fullWidth
                    type="text"
                    placeholder="Latitud"
                    margin="normal"
                    name="Latitud"
                    label="Latitud"
                    value = { lat }
                    validators={['required']}
                    errorMessages={['Este campo es requerido']}
                    onChange = { 
                        (e) => onCampoChange('lat', e.target.value)
                    } />

                    <TextValidator 
                        disabled={ocultarTodo}
                        required
                        fullWidth
                        type="text"
                        placeholder="Longitud"
                        margin="normal"
                        name="Longitud"
                        label="Longitud"
                        value = { lng }
                        validators={['required']}
                        errorMessages={['Este campo es requerido']}
                        onChange = {
                            (e) => onCampoChange('lng', e.target.value)
                        }
                    />

                    <FormControl component="fieldset" style ={{ marginTop: '17px'}} disabled={ocultarTodo}>
                        <FormHelperText>Estado</FormHelperText>
                        <FormGroup>
                            <FormControlLabel
                                control = {
                                    <Checkbox defaultChecked
                                            checked = { checkEstado }
                                            style={{color: '#7a2682'}}
                                            onChange={ (e) => onChangeCombobox('estado', e.target.checked) }
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                                label = { estado }/>
                        </FormGroup>
                    </FormControl> 

                    <FormControl component="fieldset" style ={{ marginTop: '17px'}} disabled={ocultarTodo}>
                        <FormHelperText>Informar App</FormHelperText>
                        <FormGroup>
                            <FormControlLabel
                                control = {
                                    <Checkbox defaultChecked
                                            checked = { informarapp }
                                            style={{color: '#7a2682'}}
                                            onChange={ (e) => onChangeCheckbox('informarapp', e.target.checked) }
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                                label = { informarapp === true ? "Si" : "No" } />
                        </FormGroup>
                    </FormControl> 

                    <TextField
                        disabled={ocultarTodo}
                        fullWidth
                        type="text"
                        placeholder="Descripción"
                        margin="normal"
                        name="Descripción"
                        label="Descripción"
                        value = { descripcion }
                        onChange = {
                            (e) => onCampoChange('descripcion', e.target.value)
                        }
                    />    
                                
                    <TextField
                        disabled={ocultarTodo}
                        fullWidth
                        type="text"
                        placeholder="Imagen 1"
                        margin="normal"
                        name="Imagen 1"
                        label="Imagen Pequeña"         
                        value = { image }           
                        onChange = { 
                            (e) => onCampoChange('image', e.target.value)
                        }
                    />

                    <TextField disabled={ocultarTodo}
                            type="text"
                            placeholder="Imagen 2"
                            fullWidth margin="normal"
                            name="Imagen 2"
                            label="Imagen Grande"
                            value={image2}
                            onChange={(e) => onCampoChange('image2', e.target.value)}/>

                    <TextField disabled={ocultarTodo}
                            type="text"
                            placeholder="Tags"
                            fullWidth margin="normal"
                            name="Tags"
                            label="Tags"
                            value={tags}
                            onChange={(e) => onCampoChange('tags', e.target.value)}/>

                    <TextField disabled={ocultarTodo}
                            type="text"
                            placeholder="Legales"
                            fullWidth margin="normal"
                            name="Legales"
                            label="Legales"
                            value={legales}
                            onChange={(e) => onCampoChange('legales', e.target.value)}/>       
            
                    <TextField disabled={ocultarTodo}
                            type="number"
                            placeholder="Valor"
                            fullWidth margin="normal"
                            name="Valor"
                            label="Valor"
                            value={valor}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            onChange={(e) => onCampoChange('valor', e.target.value)}/> 

                    <TextValidator
                        fullWidth
                        disabled={ocultarTodo}
                        type="number"
                        placeholder="Porcentaje"
                        margin="normal"
                        name="Porcentaje"
                        label="Porcentaje"
                        value={porcentaje}                            
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            min: "0", max: "99", step: "0.1"
                        }}
                        onChange={(e) => onCampoChange('porcentaje', e.target.value)}/>

                    <TextField disabled={ocultarTodo}
                            label="Valor Fecha Desde"
                            type="date"   
                            style ={{ marginTop: '17px', marginBottom: '10px' }}                       
                            value={valorFechaDesde ? valorFechaDesde : "2017-05-24"}
                            onChange = {(e) => { onCampoChange('valorFechaDesde',e.target.value) }}
                            InputLabelProps={{
                                shrink: true,
                            }}/>
                                    
                    <Autocomplete   
                        disabled={ocultarTodo}                     
                        fullWidth
                        id="size-small-standard-multi"
                        size="small"       
                        multiple    
                        onChange = { (e, newValue) => {
                            onCampoChange('listaCategoriasCombobox',newValue);
                        }}
                        options = { categorias }
                        style = {{
                            marginTop: '17px',
                            marginBottom: '10px'
                        }}
                        getOptionLabel={option => option.descripcion ? option.descripcion : option}
                        renderInput = { (params) => (
                            <TextField
                                {...params}                                                              
                                variant="standard"
                                label="Categorías"
                                placeholder="Categorías"/>
                        )}
                    />
                
                    <TextValidator
                        disabled={ocultarTodo}
                        required
                        fullWidth
                        type="text"
                        placeholder="Teléfono"
                        margin="normal"
                        name="Teléfono"
                        label="Teléfono"
                        value = { telefono }
                        validators = {['required']}
                        errorMessages = {['Este campo es requerido']}
                        onChange = {
                            (e) => onCampoChange('telefono', e.target.value)
                        }
                    /> 

                    <TextValidator
                        disabled={ocultarTodo}                        
                        fullWidth
                        type="text"
                        placeholder="Email"
                        margin="normal"
                        name="Email"
                        label="Email"
                        value = { email }                        
                        onChange = { 
                            (e) => onCampoChange('email', e.target.value)
                        }
                    /> 

                    <TextValidator
                        disabled={ocultarTodo}                        
                        fullWidth
                        type="text"
                        placeholder="Email Administrativo"
                        margin="normal"
                        name="emailAdministrativo"
                        label="Email Administrativo"
                        value = { emailAdministrativo }                        
                        onChange = { 
                            (e) => onCampoChange('emailAdministrativo', e.target.value)
                        }
                    /> 
                    
                        <br/>
                        <br/>

                        <Collapse in = { mostarAlert } fullWidth>
                            <Alert severity = { tipoAlert }
                                action = {
                                    <IconButton aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    onCerrarAlertClick();    
                                                }}>
                                                <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }>
                                {mensajeAlert}
                            </Alert>
                        </Collapse>  

                        <br/>
                        <br/>

                        <Button disabled = {ocultarBotonAgregar}
                                variant="contained"
                                style={{backgroundColor: '#7a2682', color:'#ffffff'}}
                                type="submit" >
                            Agregar
                        </Button>

                        <Button disabled = {ocultarBotonVolver}
                                variant="contained"
                                color="secundary"                            
                                onClick={onVolverClick}>
                            Volver
                        </Button>     

                    </ValidatorForm>

                    <Backdrop open = { mostrarBackDrop }>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            );
        }
        else
        {
            return (
                <div>
                    <div style={{color:'#ffffff'}}>Es necesario estar logueado para acceder a esta pagina</div>
                    <br/>
                    <br/>
                    <Button                     
                        variant = "contained"
                        style = {{ backgroundColor: '#7a2682', color:'#ffffff' }}
                        onClick = { onNoLogin } >
                            Ir a Login
                    </Button>  
                </div>         
            );
        }
    }
}

const style ={
    display: 'flex',
    justifyContent: 'center'
};

const styleForm = {
    padding: '30px',
    backgroundColor: '#ffffff'
}

export default AgregarBase;
