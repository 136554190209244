import axios from 'axios';

class UsuariosAPI {
    uriBase = process.env.REACT_APP_URI_BASE;
    endPoint = 'api/Usuarios';

    postLogin(usuario) {
        return axios.post(this.uriBase + this.endPoint + '/login', usuario);
    }

}

export default UsuariosAPI;
