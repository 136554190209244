import React, { Component } from 'react';
import CartillaOrigenesAPI from '../../services/CartillaOrigenesAPI';
import PrestadorModificacionesAPI from "../../services/PrestadorModificacionesAPI";
import PrestadoresApi from "../../services/PrestadoresAPI";
import LocalidadesAPI from "../../services/LocalidadesAPI";
import CategoriasAPI from "../../services/CategoriasAPI";
import EditarBase from "../../components/EditarBase";

class EditarPrestador extends Component{

    constructor(props){
        super(props);

        this.state = {
            titulo: 'Editar Prestador',
            cuponId: 0, 
            nombre: '',
            nombreImagen: '',
            codigoQR: '',
            idCartillaOrigen: 0,
            cartillaOrigenes:[],
            cartillaOrigenCombobox:[],
            address: '',
            idLocation: 0,
            locations: [],
            locationCombobox: [],
            lat: 0,
            lng: 0,
            estado: '',
            descripcion: '',
            image: '',
            image2: '',
            tags: '',
            legales: '',
            valor: 0,
            valorFechaDesde: '',
            idCategorias: [],
            categorias: [],
            listaCategoriasCombobox: [],
            telefono: '',
            email: '',
            emailAdministrativo: '',
            porcentaje: 0,
            ip: '',
            fechaCreacion: null,
            anchorEl: null,
            dataModificaciones: [],
            fechaModificacion: null ,
            usuarioModificacionActual: '',
            usuarioModificacion: '',
            usuario: window.localStorage.getItem("usuario"),      
            direccionIp: '',
            informarapp: true,
            tipoAlert: "",
            mostarAlert: false,
            mensajeAlert: "",
            ocultarTodo: false,
            ocultarBotonEditar: true,
            ocultarBotonVolver: false,
            mostrarBackDrop: false,
            deshabilitarHistorico: true
        }

        this.onGetPrestador = this.onGetPrestador.bind(this);
        this.onGrabarClick = this.onGrabarClick.bind(this);
        this.onCampoChange = this.onCampoChange.bind(this);
        this.onChangeCombobox = this.onChangeCombobox.bind(this);
        this.onVolverClick = this.onVolverClick.bind(this);
        this.onCerrarAlertClick = this.onCerrarAlertClick.bind(this);
        this.onNoLogin = this.onNoLogin.bind(this);
        this.onClickDownloadQR = this.onClickDownloadQR.bind(this);
        this.onVerHistoricoClick = this.onVerHistoricoClick.bind(this);
        this.onCerrarPopUp = this.onCerrarPopUp.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    }

    componentWillMount() {
        /*const internalIp = require('internal-ip');   
        (async () => {
            console.log(await internalIp.v6());
            //=> 'fe80::1'
         
            console.log(await internalIp.v4());
            //=> '10.0.0.79'
        })();

        this.setState({ ip: internalIp.v4()});
        console.log(internalIp.v4.sync());       
        console.log('window',window);*/ 
    }

    componentDidMount() {
        this.setState({ 
            cuponId : window.localStorage.getItem("cuponId"),
            usuarioModificacionActual: window.localStorage.getItem("usuario")
        });        
        this.onGetPrestador(window.localStorage.getItem("cuponId"));    
    }

    onGetPrestador(cuponId) {
        var prestadoresApi = new PrestadoresApi();        
        prestadoresApi.getPrestador(cuponId)        
            .then(res => {     
                
                this.setState({ 
                    cuponId: res.data.cuponId, 
                    nombre: res.data.nombre,
                    nombreImagen: res.data.nombreImagen,
                    codigoQR: res.data.codigoQR,
                    idCartillaOrigen: res.data.idCartillaOrigen,
                    address: res.data.address,
                    idLocation: res.data.idLocation, 
                    lat: res.data.lat,
                    lng: res.data.lng,
                    estado: res.data.estado,
                    descripcion: res.data.descripcion,
                    image: res.data.image,
                    image2: res.data.image2,
                    tags: res.data.tags,
                    legales: res.data.legales,
                    valor: res.data.valor,
                    valorFechaDesde: res.data.valorFechaDesde,                    
                    telefono: res.data.telefono,
                    email: res.data.email,
                    emailAdministrativo: res.data.emailAdministrativo,
                    idCategorias: res.data.idCategorias,
                    porcentaje: res.data.porcentaje,
                    fechaCreacion: res.data.fechaCreacion,
                    fechaModificacion: res.data.fechaModificacion,
                    usuarioModificacion: res.data.usuarioModificacion ?? '',
                    informarapp: res.data.informarApp
                 });  

                 this.setState({
                    titulo: this.state.titulo + ': ' + this.state.nombre,
                    deshabilitarHistorico: this.state.usuarioModificacion.length > 0 ? false : true
                });

                 var localidadesAPI = new LocalidadesAPI();
                 localidadesAPI.getLocalidades(this.state.idLocation)
                     .then(res => {                
                         this.setState({locations: res.data.localidades,
                                        locationCombobox: res.data.locationCombobox});        
                     })
                     .catch(error => {
                         console.log('ERROR obteniendo localidades: ' + error);
                     });  
              
                 var cartillaOrigenesAPI = new CartillaOrigenesAPI();
                 cartillaOrigenesAPI.getCartillaOrigenes(this.state.idCartillaOrigen)
                     .then(res => {        
                         this.setState({cartillaOrigenes: res.data.cartillaOrigenes,
                                        cartillaOrigenCombobox: res.data.cartillaOrigenCombobox});  
                     })
                     .catch(error => {
                         console.log('ERROR obteniendo cartilla origines: ' + error);
                     });

                 var categoriasAPI = new CategoriasAPI();     
                 categoriasAPI.postCategorias(this.state.idCategorias ?? [])
                     .then(res => {                
                         this.setState({ocultarBotonEditar: false,
                                        categorias: res.data.categorias,
                                        listaCategoriasCombobox: res.data.listaCategoriasCombobox}); 
                    })
                    .catch(error => {
                        console.log('ERROR obteniendo categorias: ' + error);
                    });     
                
                var prestadorModificacionesAPI = new PrestadorModificacionesAPI();     
                prestadorModificacionesAPI.getPrestadorModificaciones(this.state.cuponId)
                    .then(res => {                
                        this.setState({ dataModificaciones: res.data });   
                    })
                    .catch(error => {
                        console.log('ERROR obteniendo prestadores historico: ' + error);
                    });                  
            })
            .catch(error => {
                console.log('ERROR obtener prestador' + error);
            });        
    }

    onGrabarClick() {
        
        this.setState({ ocultarTodo: true,
                        ocultarBotonEditar: true,
                        ocultarBotonVolver: true,
                        mostrarBackDrop: true });

        let _prestadorModificacion = {
            idPrestador: this.state.cuponId, 
            usuario: this.state.usuario,      
            direccionIp: window.location.host.substring(0, 15)
        };

        console.log(this.state.ip);
       
        let _prestador = {
            cuponId: this.state.cuponId, 
            nombre: this.state.nombre,
            idCartillaOrigen: this.state.idCartillaOrigen,
            cartillaOrigen: this.state.cartillaOrigenCombobox,
            address: this.state.address,
            idLocation: this.state.idLocation,
            location: this.state.locationCombobox,
            lat: parseFloat(this.state.lat),
            lng: parseFloat(this.state.lng),
            estado: this.state.estado,
            descripcion: this.state.descripcion,
            image: this.state.image,
            image2: this.state.image2,
            tags: this.state.tags,
            legales: this.state.legales,
            valor: parseInt(this.state.valor ?? 0,10),
            valorFechaDesde: this.state.valorFechaDesde,            
            telefono: this.state.telefono,
            email: this.state.email,
            emailAdministrativo: this.state.emailAdministrativo,
            idCategorias: this.state.idCategorias,
            listaCategorias: this.state.listaCategoriasCombobox,
            porcentaje: parseFloat(this.state.porcentaje),
            usuarioModificacion: this.state.usuarioModificacionActual,
            informarapp: this.state.informarapp
        };
       
        var prestadoresApi = new PrestadoresApi();
        prestadoresApi.putPrestador(_prestador)
            .then(res => {
                this.setState({ tipoAlert: "success",
                                mostarAlert:true,
                                mensajeAlert: "El Prestador se ha editado satisfactoriamente",
                                ocultarTodo: false,
                                ocultarBotonEditar: false,
                                ocultarBotonVolver: false,
                                mostrarBackDrop: false });                          

                var prestadorModificacionesAPI = new PrestadorModificacionesAPI();     
                prestadorModificacionesAPI.postPrestadorModificaciones(_prestadorModificacion)
                    .then(res => {                
                        console.log('OK prestadores');               
                    })
                    .catch(error => {
                        console.log('ERROR postPrestadorModificaciones: ' + error);
                    });     
            })
            .catch(error => {

                let _tipoAlert; 
                let _mensaje;  

                if (error.response) {
                    if (error.response.status === 400) {
                        _tipoAlert = "error";
                    } else {
                        _tipoAlert = "warning";
                    }
                    _mensaje = error.response.status + ' - ' + error.response.data.mensaje;
                } else if (error.request) {
                    _tipoAlert = "error";
                    _mensaje = "Error al intentar agregar prestador";
                } else {      
                    _tipoAlert = "error";  
                    _mensaje = error.message;                
                }                

                this.setState({ tipoAlert: _tipoAlert,
                                mostarAlert: true,
                                mensajeAlert: _mensaje,
                                ocultarTodo: false,
                                ocultarBotonEditar: false,
                                ocultarBotonVolver: false,
                                mostrarBackDrop: false  });
            });              
    }

    onCampoChange(campo, value) {      
        this.setState({ [campo]: value });
    }

    onChangeCombobox(campo, checked) {

        if (checked) 
        {
            this.setState({ [campo]: 'Activo' });
        }
        else
        {
            this.setState({ [campo]: 'Inactivo' });
        }
    }

    onVolverClick() {
        window.localStorage.removeItem("cuponId");
        this.props.history.push('/prestadores');
    }

    onCerrarAlertClick() {
        window.localStorage.removeItem("cuponId");
        this.setState({ mostarAlert:false });
        this.props.history.push('/prestadores');
    }

    onNoLogin()
    {
        this.props.history.push('/');
    }

    onClickDownloadQR(cuponId, nombreImagen) {
        const canvas = document.getElementById(cuponId);
        const pngUrl = canvas
                            .toDataURL("image/jpg")
                            .replace("image/jpg", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = nombreImagen;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    onVerHistoricoClick(event) {
        this.setState({anchorEl: event.currentTarget});
    };
    
    onCerrarPopUp() {
        this.setState({anchorEl: null});
    };

    onChangeCheckbox(campo, checked) {
        this.setState({ [campo]: checked });      
    }

    render() {

        const {
            titulo,
            cuponId, 
            nombre,
            nombreImagen,
            codigoQR,
            idCartillaOrigen,            
            cartillaOrigenes,
            cartillaOrigenCombobox,
            address,
            idLocation,
            locations,
            locationCombobox,
            lat,
            lng,
            estado,
            descripcion,
            image,
            image2,
            tags,
            legales,
            valor,
            valorFechaDesde,
            idCategorias,
            categorias,
            listaCategoriasCombobox,
            telefono,
            email,
            emailAdministrativo,
            porcentaje,
            fechaCreacion,
            anchorEl,
            dataModificaciones,
            fechaModificacion,
            usuarioModificacion,
            informarapp,
            tipoAlert,
            mostarAlert,
            mensajeAlert,
            ocultarTodo,
            ocultarBotonEditar,
            ocultarBotonVolver,
            mostrarBackDrop,
            deshabilitarHistorico
        } = this.state;

        return(
            <div>
                <EditarBase
                    titulo = { titulo }                    
                    onGrabarClick = { this.onGrabarClick }
                    onCampoChange = { this.onCampoChange }    
                    onChangeCombobox = { this.onChangeCombobox }    
                    onVolverClick = { this.onVolverClick}    
                    onCerrarAlertClick = { this.onCerrarAlertClick}     
                    onNoLogin = { this.onNoLogin }   
                    onClickDownloadQR = { this.onClickDownloadQR}
                    onVerHistoricoClick = { this.onVerHistoricoClick }
                    onCerrarPopUp = { this.onCerrarPopUp }            
                    onChangeCheckbox = { this.onChangeCheckbox }        
                    cuponId = { cuponId }
                    nombre = { nombre }
                    nombreImagen = { nombreImagen }
                    codigoQR = { codigoQR }
                    idCartillaOrigen = { idCartillaOrigen }
                    cartillaOrigenes  = { cartillaOrigenes }
                    cartillaOrigenCombobox = { cartillaOrigenCombobox }
                    address = { address }
                    idLocation = { idLocation }
                    locations = { locations }
                    locationCombobox = { locationCombobox }
                    lat = { lat }
                    lng = { lng }
                    estado = { estado }
                    descripcion = { descripcion }
                    image = { image }
                    image2 = { image2 }
                    tags = { tags }
                    legales = { legales }
                    valor = { valor }
                    valorFechaDesde = { valorFechaDesde }                    
                    idCategorias = { idCategorias }
                    categorias = { categorias }
                    listaCategoriasCombobox = { listaCategoriasCombobox }
                    telefono = { telefono }
                    email = { email }
                    emailAdministrativo = { emailAdministrativo }
                    porcentaje = { porcentaje }
                    informarapp = { informarapp }
                    fechaCreacion = { fechaCreacion }
                    anchorEl = { anchorEl }
                    dataModificaciones = { dataModificaciones }
                    fechaModificacion = { fechaModificacion }
                    usuarioModificacion = { usuarioModificacion }
                    tipoAlert = { tipoAlert }
                    mostarAlert = { mostarAlert } 
                    mensajeAlert = { mensajeAlert }
                    ocultarTodo = { ocultarTodo }
                    ocultarBotonEditar = { ocultarBotonEditar }
                    ocultarBotonVolver = { ocultarBotonVolver }
                    mostrarBackDrop = { mostrarBackDrop }
                    deshabilitarHistorico = { deshabilitarHistorico }
                />
            </div>
        );
    }
}

export default EditarPrestador;
