import axios from 'axios';

class CartillaOrigenesAPI {
    uriBase = process.env.REACT_APP_URI_BASE;
    endPoint = 'api/CartillaOrigenes';

    getCartillaOrigenes(idCartillaOrigen) {
        return axios.get(this.uriBase + this.endPoint + '/' + idCartillaOrigen);
    }

}

export default CartillaOrigenesAPI;